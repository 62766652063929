<template>
	<div>
		<!-- filters -->
		<v-row v-if="0" class="pb-3">
			<v-col md="3" sm="6" cols="12">
				<v-text-field
					:label="$t('search')"
					hide-details
					outlined
					dense
					prepend-inner-icon="mdi-magnify"
					class="rounded-medium"
				></v-text-field>
			</v-col>
			<v-spacer/>
			<v-col md="4" sm="6" cols="12">
				<v-row>
					<v-col sm="6" cols="12">
						<v-text-field
							:label="('from')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-calendar"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-col sm="6" cols="12">
						<v-text-field
							:label="('to')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-calendar"
							class="rounded-medium"
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- statistics -->
		<v-card v-if="0" :loading="loading" flat class="rounded-medium mb-2">
			<v-card-text class="py-6">
				<v-row>
					<!-- visitors -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.visitors')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">N/A</span>
								<v-chip pill small color="red lighten-5 error--text mt-1">
									<span class="me-1">5%</span>
									<v-icon small>mdi-trending-down</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								5 {{$t('Dashboard.descriptions.visitors')}}
							</p>
						</div>
					</v-col>
					
					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.smAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- users -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.users')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">{{statics.user_count || 0}}</span>
								<v-chip pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">26%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								12 {{$t('Dashboard.descriptions.today')}}
							</p>
						</div>
					</v-col>
					
					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.lgAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- disabled -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.disabled-products')}}
							</p>
							<div class="d-flex mt-2 mb-3">
								<span class="number-large main--text me-3">{{statics.disabled_product || 0}}</span>
							</div>
							<p class="text-caption mb-0">
								{{$t('Dashboard.descriptions.out-of-$-products', { number: statics.product_count || 0 })}}
							</p>
						</div>
					</v-col>

					<!-- divider -->
					<v-col cols="auto" class="px-0" v-if="$vuetify.breakpoint.smAndUp">
						<v-divider vertical/>
					</v-col>
					
					<!-- revenue -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.revenue')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">$0</span>
								<v-chip pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">16%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p class="text-caption mb-0">
								236 {{$t('Dashboard.descriptions.orders')}}
							</p>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- order & products -->
		<v-row class="mt-0 mb-0">
			<!-- order status -->
			<v-col v-if="showOrders" lg="8" md="7" cols="12">
				<v-card flat class="rounded-medium" height="100%">
					<v-card-title class="font-weight-bold main--text ps-10 pb-3">
						{{$t('Dashboard.order-status.title')}}
					</v-card-title>
					<v-card-text class="pb-6">

					</v-card-text>
				</v-card>
			</v-col>

			<!-- products views -->
			<v-col
				:lg="showOrders ? 4 : null"
				:md="showOrders ? 5 : null"
				cols="12"
			>
				<v-card min-height="350" :loading="loading" flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text ps-10 pb-3">
						{{$t('Dashboard.products-views')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<bar-chart
							:labels="labels"
							:datasets="datasets"
							:height="showOrders ? 350 : 100"
						></bar-chart>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- resent added && popular products -->
		<v-row class="mt-0">
			<!-- resent added -->
			<v-col md="5" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.resent-added-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="recentHeaders"
							:items="loading ? [] : statics.recent_add_products.slice(0, 10)"
							:loading="loading"
							:items-per-page="15"
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>
							
							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/placeholder-image.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- popular products -->
			<v-col md="7" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.popular-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="popularHeaders"
							:items="loading ? [] : statics.popular_product.slice(0, 10)"
							:loading="loading"
							:items-per-page="15"
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>

							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/placeholder-image.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import BarChart from '@/components/BarChart.vue';
import { customColors } from '@/plugins/vuetify'
import { mapState } from 'vuex';
import { orders } from '@/configs/routes.config';
export default {
	components: { BarChart },
	name: 'Dashboard',

	data: () => ({
		// helpers
		loading: false,

		// configs
		showOrders: orders.show,

		// data
		labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', ''],
		datasets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	}),

	computed: {
		...mapState({
			statics: state => state.dashboard.statics
		}),

		recentHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			if (this.showOrders) {
				headers.splice(headers.length, 0, { text: this.$t('headers.revenue'), value: 'revenue', align: 'center' },);
			}
			return headers
		},
		
		popularHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			if (this.showOrders) {
				headers.splice(
					headers.length,
					0,
					{ text: this.$t('headers.revenue'), value: 'revenue', align: 'center' },
					{ text: this.$t('headers.orders'), value: 'order', align: 'center' }
				);
			}
			return headers
		},
	},

	created() {
		this.loading = true;
		this.$store.dispatch('dashboard/fetchAll')
			.then((data) => {
				const labels = [];
				const staticsData = [];
				data.products_view.forEach(product => {
					labels.push(product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name);
					staticsData.push(product.view)
				});
				this.labels = labels;
				this.datasets = [
					{
						label: 'views',
						backgroundColor: customColors.primary,
						data: staticsData,
						borderRadius: 5
					}
				]
				this.loading = false;
			})
	}
};
</script>

<style lang="scss" scoped>
.statistic-card {
	width: 70%;
	margin: 0 auto;
}
</style>
