import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		SEO: {},
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/home').then(({ data }) => {
				commit(types.STORE_SEO, data);
				return data;
			})
		},
		/* eslint-disable camelcase */
		create({ commit }, { home_description, home_keywords, news_description, news_keywords }) {
			return axios.post('/home', { home_description, home_keywords, news_description, news_keywords })
		}
		/* eslint-enable camelcase */
	},

	mutations: {
		[types.STORE_SEO](state, SEO) {
			state.SEO = SEO;
		}
	}
}