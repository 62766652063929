<template>
	<v-app-bar
		app
		color="white"
		height="55"
		elevation="2"
	>
		<!-- toggle drawer -->
		<v-btn
			text
			fab
			small
			@click="$emit('change-drawer')"
			class="me-2"
		>
            <v-icon>mdi-menu</v-icon>
        </v-btn>
		
		<!-- title -->
		<h1 class="text-md-h5 text-h6 primary--text font-weight-medium text-capitalize">
			{{getRouteTitle()}}
		</h1>
		<v-spacer/>

		<!-- notification & settings -->
		<div class="d-flex align-center align-self-stretch">
			<template v-if="0 && $vuetify.breakpoint.smAndUp">
				<v-btn text small icon>
					<v-icon>mdi-bell</v-icon>
				</v-btn>
				<v-btn text small icon class="mx-md-6 mx-2">
					<v-icon>mdi-cog</v-icon>
				</v-btn>

				<v-divider vertical class="mx-2"/>
			</template>

			<!-- user menu -->
			<v-menu offset-y>
				<!-- menu activator -->
				<template v-slot:activator="{ attrs, on }">
					<v-btn
						text
						:large="$vuetify.breakpoint.smAndUp"
						:small="$vuetify.breakpoint.smAndDown"
						class="px-3 text-capitalize"
						v-bind="attrs"
						v-on="on"
					>
						<template v-if="$vuetify.breakpoint.smAndUp">
							<v-avatar size="35">
								<v-img src="../assets/user.png"/>
							</v-avatar>
							<span class="mx-2">
								{{self.name ? self.name : 'Username'}}
							</span>
						</template>
						<v-icon>mdi-chevron-down</v-icon>
					</v-btn>
				</template>

				<!-- menu content -->
				<v-list nav dense>
					<v-list-item v-if="0 && $vuetify.breakpoint.smAndDown">
						<v-list-item-icon><v-icon>mdi-bell</v-icon></v-list-item-icon>
						<v-list-item-title>{{ $t('Navbar.menu.notification') }}</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="0 && $vuetify.breakpoint.smAndDown">
						<v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
						<v-list-item-title>{{ $t('Navbar.menu.settings') }}</v-list-item-title>
					</v-list-item>
					
					<!-- logout -->
					<v-list-item @click="logout">
						<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
						<v-list-item-title>{{ $t('Navbar.menu.logout') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
	</v-app-bar>
</template>

<script>
import routes from '@/router/routes';
import * as routesConfig from '@/configs/routes.config';
import { mapState } from 'vuex';
export default {
	name: 'Navbar',

	data: () => ({
	}),

	computed: {
		...mapState({
			self: state => state.auth.self
		})
	},

	methods: {
		getRouteTitle() {
			const route = routes.find(c => c.name === this.$route.name);
			
			if (route.isAddEditPage) {
				const action = (this.$route.params.id ? this.$t('edit') : this.$t('add')) + ' ';
				return action + route.title[this.$i18n.locale].singular;
			}
			return route.title[this.$i18n.locale].plural;
		},

		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.replace({ name: routesConfig.login.name });
		}
	}
};
</script>

<style>
</style>