<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col lg="9" md="8" cols="12" order-md="0" order="1" class="pb-md-0">
				<v-row class="mt-0 mb-2">
					<!-- product name -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="name"
							:label="$t('inputs.product-name')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
						></v-text-field>
					</v-col>

					<!-- summary -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="summary"
							:label="$t('inputs.summary')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>
					
					<!-- categories -->
					<v-col lg="6" cols="12">
						<v-autocomplete
							v-model="categoriesIds"
							:label="$t('inputs.category')"
							:items="categories"
							:item-text="item => getCategoryItemText(item)"
							item-value="id"
							multiple
							hide-details
							outlined
							dense
							chips
							small-chips
							deletable-chips
							class="rounded-medium"
							:loading="loading || assetsLoading"
							:disabled="loading || submitLoading"
							:rules="rules.requiredAsArray"
						></v-autocomplete>
					</v-col>

					<!-- filter -->
					<v-col v-if="showFilters" lg="6" cols="12">
						<v-autocomplete
							v-model="filterIds"
							:label="$t('inputs.filter')"
							:items="filters"
							item-text="name"
							item-value="id"
							multiple
							hide-details
							outlined
							dense
							chips
							small-chips
							deletable-chips
							class="rounded-medium"
							:loading="loading || assetsLoading"
							:disabled="loading || submitLoading"
						></v-autocomplete>
					</v-col>

					<!-- keywords -->
					<v-col v-if="showKeywords" lg="6" cols="12">
						<v-autocomplete
							v-model="keywordIds"
							:label="$t('inputs.keywords')"
							:items="keywords"
							item-text="name"
							item-value="id"
							multiple
							hide-details
							outlined
							dense
							chips
							small-chips
							deletable-chips
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-autocomplete>
					</v-col>
					
					<!-- date-of-publication & is-featured -->
					<v-col lg="6" cols="12" class="d-flex flex-sm-row flex-column align-sm-center">
						<!-- date -->
						<v-menu
							ref="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									:label="$t('inputs.date')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									prepend-inner-icon="mdi-calendar"
									readonly
									clearable
									:loading="loading"
									:disabled="loading || submitLoading"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
								min="1950-01-01"
								@change="$refs.menu.save(date)"
							></v-date-picker>
						</v-menu>

						<!-- is-featured -->
						<v-checkbox
							v-model="isFeatured"
							:label="$t('inputs.is-featured')"
							hide-details
							outlined
							class="mt-sm-0 ms-4 pt-0"
						></v-checkbox>
					</v-col>

					<!-- price -->
					<v-col v-if="showPrice" lg="6" cols="12">
						<v-text-field
							v-model.number="price"
							:label="$t('inputs.price')"
							hide-details
							outlined
							dense
							type="number"
							min="0"
							class="rounded-medium"
							hide-spin-buttons
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- file -->
					<v-col v-if="showFileUpload" lg="6" cols="12">
						<v-file-input
							v-model="file"
							:label="$t('inputs.file')"
							hide-details
							outlined
							prepend-icon
							prepend-inner-icon="$file"
							dense
							class="rounded-medium"
							truncate-length="100"
							:loading="loading"
							:disabled="loading || submitLoading"
							:append-icon="file && uploadedFileUrl ? 'mdi-download' : null"
							@change="uploadedFileUrl = null"
							@click:append="showFile(uploadedFileUrl)"
						></v-file-input>
					</v-col>
				</v-row>

				<!-- SEO fields -->
				<template v-if="showSEO">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('SEO-fields')}}
					</div>
					<v-row>
						<!-- SEO keywords -->
						<v-col cols="12" >
							<v-text-field
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details="auto"
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								hint="keywords should separated by (,) eg: keyword1, keyword2"
								@input="SEOkeywords = SEOkeywords.replace(/(\w) /g, '$1, ')"
							></v-text-field>
						</v-col>

						<!-- SEO description -->
						<v-col cols="12">
							<v-textarea
								v-model="SEOdescription"
								:label="$t('inputs.seo-description')"
								outlined
								dense
								auto-grow
								rows="1"
								no-resize
								counter
								:hint="SEOdescription && SEOdescription.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
								persistent-hint
								:color="SEOdescription && SEOdescription.length > 155 ? 'warning' : null"
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								@keypress.enter.prevent
								@change="SEOdescription = SEOdescription.replace(/\n/g, ' ')"
							></v-textarea>
						</v-col>
					</v-row>
				</template>

				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor v-model="description"/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>
			
			<!-- drop image -->
			<v-col md cols="12" class="flex-grow-1 pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:hide-image-name="false"
						:loading="loading"
						:disabled="loading || submitLoading"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="250"
						:drop-image-props="{
							width: 70,
							height: 70
						}"
						:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
						:clear-color="$route.params.id ? 'red' : null"
						:gap-size="4"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
						:loading="loading"
						:disabled="loading || submitLoading"
						@remove-image="removeImage"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>
				</div>
			</v-col>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
			@click="isSubmitted = true"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules'
import { sets, filters, keywords, products, orders, SEO } from '@/configs/routes.config';
import { useFileUpload } from '@/configs/global.config';
export default {
	name: 'Product',
	
	data: () => ({
		// helpers
		loading: false,
		assetsLoading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		uploadedFileUrl: null,
		rules,

		// configs
		showFilters: filters.show,
		showKeywords: keywords.show,
		showSets: sets.show,
		showPrice: orders.show,
		showSEO: SEO.show,
		showFileUpload: useFileUpload,

		// data
		name: null,
		summary: null,
		categoriesIds: [],
		filterIds: [],
		keywordIds: [],
		date: null,
		isFeatured: false,
		price: null,
		file: null,
		SEOdescription: '',
		SEOkeywords: '',
		description: null,
		mainImage: null,
		images: [],
	}),

	computed: {
		...mapState({
			sets: state => state.sets.sets.filter(c => !c.deleted_at),
			categories: state => state.categories.categories.filter(c => !c.deleted_at),
			filters: state => state.filters.filters.filter(c => !c.deleted_at),
			keywords: state => state.keywords.keywords.filter(c => !c.deleted_at),
		}),

		...mapGetters({
			getSetById: 'sets/getSetById',
			getCategoryById: 'categories/getCategoryById',
		})
	},

	methods: {
		getCategoryItemText(item) {
			if (this.sets.length && this.showSets) {
				const setsNames = [];
				item.setsIds.forEach(id => {
					setsNames.push(this.getSetById(id).name);
				});
				return this.getCategoryById(item.id).name + ' - ' + setsNames.join(',');
			} else {
				return item.name
			}
		},

		showFile(url) {
			window.open(url)
		},

		submit() {
			if (this.$refs.form.validate() && this.mainImage) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				// submit
				this.$store.dispatch(`products/${actionName}`, {
					id: this.$route.params.id,
					name: this.name,
					summary: this.summary,
					code: 1,
					isFeatured: this.isFeatured,
					price: this.price,
					dateOfPublication: this.date,
					description: this.description,
					categoryIds: this.categoriesIds,
					filterIds: this.filterIds,
					keywordIds: this.keywordIds,
					image: typeof this.mainImage !== 'string' ? this.mainImage : null,
					file: this.file && this.file.size ? this.file : null,
					descriptionSeo: this.SEOdescription,
					keywordsSeo: this.SEOkeywords
				}).then((data) => {
					const message =
						actionName === 'update'
							? this.$t('$-is-updated-successfully', { name: this.$t('Products.product') })
							: this.$t('$-is-created-successfully', { name: this.$t('Products.product') });
					this.$eventBus.$emit('show-snackbar', false, message);

					// upload the other images
					if (images.length) {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'))
						this.$store.dispatch('products/uploadImages', {
							id: data.id,
							images,
						}).then(() => {
							this.$router.push({ name: products.name });
						}).finally(() => {
							this.submitLoading = false;
						})
					} else {
						this.$router.push({ name: products.name });
					}
				}).finally(() => {
					if (!images.length) {
						this.submitLoading = false;
					}
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				if (imageId) {
					this.$store.dispatch('products/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}
		}
	},

	created() {
		const promises = [];
		if (this.sets.length === 0) promises.push(this.$store.dispatch('sets/fetchAll'));
		if (this.categories.length === 0) promises.push(this.$store.dispatch('categories/fetchAll'));
		if (this.filters.length === 0) promises.push(this.$store.dispatch('filters/fetchAll'));
		if (this.keywords.length === 0) promises.push(this.$store.dispatch('keywords/fetchAll'));

		if (this.$route.params.id) {
			this.loading = true;

			promises.push(
				this.$store.dispatch('products/fetchBySlug', {
					slug: this.$route.params.slug
				}).then((res) => {
					const data = res;
					this.name = data.name;
					this.summary = data.summary;
					this.categoriesIds = data.categoriesIds;
					this.filterIds = data.filterIds;
					this.keywordIds = data.keywordsIds;
					this.date = data.DateOfPublication ? moment(data.DateOfPublication).format('YYYY-MM-DD') : null;
					this.isFeatured = data.isFeatured;
					this.price = data.price;
					this.file = null;
					this.file = res.files[0] ? new File([], this.$t('messages.there-is-uploaded-file')) : null;
					this.uploadedFileUrl = res.files[0] ? res.files[0].url : null;
					this.SEOdescription = null;
					this.SEOkeywords = null;
					this.description = data.description;
					this.mainImage = data.primaryImage.url;
					this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);
					this.SEOkeywords = data.keywordsSeo;
					this.SEOdescription = data.descriptionSeo;
					
					this.productImages = data.images.filter(c => c !== data.primaryImage.url);
				})
			)

			Promise.all(promises).finally(() => {
				this.loading = false;
			})
		} else {
			this.assetsLoading = true;
			Promise.all(promises).finally(() => {
				this.assetsLoading = false;
			})
		}
	},

	mounted() {
		// setTimeout(() => {
		// this.mainImage = 'https://www.shutterstock.com/image-photo/zorse-zebra-horse-hybrid-background-260nw-1496520587.jpg';

		// this.images = [
		// 	'https://www.shutterstock.com/image-photo/zorse-zebra-horse-hybrid-background-260nw-1496520587.jpg',
		// 	'https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Fnature%2F&psig=AOvVaw0vejuzECsWYXoQiC0J8FZd&ust=1674212169822000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCOiypIXQ1PwCFQAAAAAdAAAAABAD',
		// 	'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.commentcamarche.net%2Fapplis-sites%2Fservices-en-ligne%2F729-faire-une-recherche-a-partir-d-une-image-sur-google%2F&psig=AOvVaw0vejuzECsWYXoQiC0J8FZd&ust=1674212169822000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCOiypIXQ1PwCFQAAAAAdAAAAABAQ',
		// 	'https://imgv3.fotor.com/images/blog-cover-image/part-blurry-image.jpg',
		// ]
		// }, 500)
	}
};
</script>

<style lang="scss">
</style>