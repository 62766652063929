import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		sliders: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sliders').then(({ data }) => {
				commit(types.STORE_SLIDERS, data);
				return data;
			})
		},
		create({ commit }, { description, url, image }) {
			const formData = new FormData();
			const requestData = { description, url, image };
			
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			return axios.post('/sliders', formData);
		},
		update({ commit }, { id, description, url, image }) {
			const formData = new FormData();
			const requestData = { description, url, image };
			
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});

			formData.append('_method', 'PUT');

			return axios.post(`/sliders/${id}`, formData);
		},
		delete({ commit }, { id }) {
			return axios.delete(`/sliders/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/sliders/${id}`)
		},
	},

	mutations: {
		[types.STORE_SLIDERS](state, sliders) {
			state.sliders = sliders;
		}
	},

	getters: {
		getSlideById: state => id => state.sliders.find(c => c.id === id),
	}
}