import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		news: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/news').then(({ data }) => {
				commit(types.STORE_NEWS, data.filter(c => !c.deletedAt));
				return data.filter(c => !c.deletedAt);
			})
		},

		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/news/${slug}`).then(({ data }) => data)
		},

		create({ commit }, { title, summary, content, descriptionSeo, keywordSeo, date, isFeatured, image }) {
			const formData = new FormData();

			const requestData = { title, summary, content, descriptionSeo, keywordSeo, date, image };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			
			formData.append('isFeatured', isFeatured ? 1 : 0);

			return axios.post('/news', formData).then(({ data }) => data)
		},
		
		update({ commit }, { id, title, summary, content, descriptionSeo, keywordSeo, date, isFeatured, image }) {
			const formData = new FormData();
			
			const requestData = { title, summary, content, descriptionSeo, keywordSeo, date, image };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			formData.append('isFeatured', isFeatured ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/news/${id}`, formData).then(({ data }) => data);
		},

		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/news/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/news/${id}/images/${imageId}`)
		},

		delete({ commit }, { id }) {
			return axios.delete(`/news/${id}`)
		},

		restore({ commit }, { id }) {
			return axios.patch(`/news/${id}`)
		},
	},

	mutations: {
		[types.STORE_NEWS](state, news) {
			state.news = news;
		}
	},

	getters: {
		getNewsById: state => id => state.news.find(c => c.id === id),
		getNewsBySlug: state => slug => state.news.find(c => c.slug === slug),
	}
}