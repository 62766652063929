import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		sets: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sets').then(({ data }) => {
				commit(types.STORE_SETS, data);
				return data;
			})
		},
		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/sets/${slug}`).then(({ data }) => data)
		},
		create({ commit }, { name }) {
			return axios.post('/sets', { name })
		},
		update({ commit }, { id, name }) {
			return axios.post(`/sets/${id}`, { name, _method: 'PUT' })
		},
		delete({ commit }, { id }) {
			return axios.delete(`/sets/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/sets/${id}`)
		},
	},

	mutations: {
		[types.STORE_SETS](state, sets) {
			state.sets = sets;
		}
	},

	getters: {
		getSetById: state => id => state.sets.find(c => c.id === id),
		getSetBySlug: state => slug => state.sets.find(c => c.slug === slug),
	}
}