<template>
	<div id="preview">
		<!-- {{$route.query.url}} -->
		<v-btn
			small
			color="primary"
			height="25"
			class="rounded-medium px-2 mb-3"
			@click="$router.back()"
		>
			<v-icon size="20" class="me-1">mdi-arrow-left</v-icon>
			{{$t('back')}}
		</v-btn>
		<iframe
			id="iframe"
			:src="$route.query.url"
		></iframe>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" >
#preview {
	height: 82vh;

	#iframe {
		height: 100%;
		width: 100%;
		border-radius: 11px;
		border: 1px solid #ccc;
	}
}
</style>