import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sharedTranslates from './shared-translates.js'

Vue.use(VueI18n)

function loadLocaleMessages() {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]/i)
	const langs = [];
	const translates = [];
	const messages = {};

	locales.keys().forEach((key) => {
		// get all available languages 
		// (just create folder with the language key e.g. folder with name 'ar' in './locales')
		const matched = key.match(/([A-Za-z0-9-_]+)\//i);
		if (matched && !langs.includes(matched[1])) {
			const lang = matched[1];
			langs.push(lang)
			messages[lang] = {};
		}

		// get all available translates keys
		// (just create js or json file in langauge folder e.g. create file 'page.js' in './locales/ar' folder)
		const matchedTr = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matchedTr && !translates.includes(matchedTr[1])) {
			translates.push(matchedTr[1]);
		}
	})
	
	// set all translates messages
	locales.keys().forEach((key) => {
		// check if key is file path
		if (key.match(/[A-Za-z0-9-_,\s]+\.(json|js)/i)) {
			langs.forEach(lang => {
				translates.forEach(translate => {
					if (key.includes(`/${lang}`) && key.includes(`/${translate}.`)) {
						// match key (with js) to check if language come from js file not json
						if (key.match(/([A-Za-z0-9-_]+)\.js$/i)) {
							// so import messages which exported as default in langauge file
							messages[lang][translate] = locales(key).default;
						} else {
						// else it is json file
							messages[lang][translate] = locales(key);
						}
					}
				})
			})
		}
	})

	Object.keys(sharedTranslates).forEach((key) => {
		langs.forEach(lang => {
			messages[lang][key] = sharedTranslates[key][lang];
		})
	})
	return messages
}

export default new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: loadLocaleMessages()
})
