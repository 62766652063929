import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		aboutUs: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/aboutus').then(({ data }) => {
				commit(types.STORE_ABOUT_US, data);
				return data;
			})
		},
		
		create({ commit }, { about }) {
			return axios.post('/aboutus', { about })
		}
	},

	mutations: {
		[types.STORE_ABOUT_US](state, aboutUs) {
			state.aboutUs = aboutUs;
		}
	}
}