import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		products: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/products').then(({ data }) => {
				commit(types.STORE_PRODUCTS, data.filter(c => !c.deletedAt));
				return data.filter(c => !c.deletedAt);
			})
		},
		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/products/${slug}`).then(({ data }) => data)
		},

		create({ commit }, {
			name, summary, code, isFeatured, price, dateOfPublication, description, categoryIds, filterIds, keywordIds, descriptionSeo, keywordsSeo, image, file
		}) {
			const formData = new FormData();
			const requestData = { name, summary, code, price, dateOfPublication, description, descriptionSeo, keywordsSeo, image, file };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			formData.append('isFeatured', isFeatured ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}

			return axios.post('/products', formData).then(({ data }) => data)
		},

		update({ commit }, { 
			id, name, summary, code, isFeatured, price, dateOfPublication, description, categoryIds, filterIds, keywordIds, descriptionSeo, keywordsSeo, image, file
		}) {
			const formData = new FormData();
			const requestData = { name, summary, code, isFeatured, price, dateOfPublication, description, descriptionSeo, keywordsSeo, image, file };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			formData.append('isFeatured', isFeatured ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}
			formData.append('_method', 'PUT');

			return axios.post(`/products/${id}`, formData).then(({ data }) => data)
			// headers: { 'content-type': 'multipart/form-data' } 
		},

		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/products/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/products/${id}/images/${imageId}`)
		},

		changeState({ commit }, { id }) {
			return axios.put(`/products/${id}/change-status`);
		},

		delete({ commit }, { id }) {
			return axios.delete(`/products/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/products/${id}`)
		},
	},

	mutations: {
		[types.STORE_PRODUCTS](state, products) {
			state.products = products;
		}
	},

	getters: {
		getFilterById: state => id => state.products.find(c => c.id === id),
		getFilterBySlug: state => slug => state.products.find(c => c.slug === slug),
	}
}