<template>
	<div>
		<VFileManger
			v-model="fileManager"
			@image-selected="setImage"
		/>
		<editor
			v-model="localeValue"
			:id="id"
			api-key="76xbclk6ft4lxzhex7or5smgx23381bj94berghpfi44m5cy"
			:directionality="$i18n.locale === 'en' ? 'rtl' : 'ltr'"
			path_absolute="/"
			:init="inits"
		/>
	</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import VFileManger from '@/components/VFileManger.vue'

export default {
	name: 'TextEditor',

	props: {
		id: { type: String, default: 'editor' },
		value: { type: String }
	},

	data() {
		return {
			fileManager: null,
			imagePickerCallBack: null,
		}
	},

	components: {
		Editor,
		VFileManger
	},
	
	computed: {
		localeValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue)
			}
		},

		inits() {
			return {
				height: 450,
				content_css: 'gray',
				skin: 'gray',
				skin_url: '../css/tinyMCE/skins',
				// image_uploadtab: false,
				// images_upload_credentials: true,
				image_title: true,
				automatic_uploads: true,
				file_picker_types: 'image',
				statusbar: false,
				file_browser_callback: function(fieldName, url, type, win) {
					// const x = window.innerWidth || document.documentElement.clientWidth || document.querySelector('body').clientWidth;
					// const y = window.innerHeight || document.documentElement.clientHeight || document.querySelector('body').clientHeight;

					// let cmsURL = BASE_URL + '/file-manager/all?field_name=' + fieldName;
					// if (type === 'image') {
					// 	cmsURL = cmsURL + '&type=Images';
					// } else {
					// 	cmsURL = cmsURL + '&type=Files';
					// }

					// // FIXME
					// window.tinymce.activeEditor.windowManager.open({
					// 	file: cmsURL,
					// 	title: 'Filemanager',
					// 	width: x * 0.8,
					// 	height: y * 0.8,
					// 	resizable: 'yes',
					// 	close_previous: 'no'
					// });
					win.document.forms[0].elements[fieldName].value = 'my browser value';
				},
				file_picker_callback: (callback, value, meta) => {
					/*
						const input = document.createElement('input');
						input.setAttribute('type', 'file');
						input.setAttribute('accept', 'image/*');
						input.onchange = function () {
							const file = this.files[0];

							const reader = new FileReader();
							// reader.onload = function (reader,cd) {
							reader.onload = function () {
								const id = 'blobid' + (new Date()).getTime();
								const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
								const base64 = reader.result.split(',')[1];
								const blobInfo = blobCache.create(id, file, base64);
								blobCache.add(blobInfo);

								callback(blobInfo.blobUri(), { title: file.name });
							};
							reader.readAsDataURL(file);
						};
						input.click();
					*/
					
					this.fileManager = true;
					this.imagePickerCallBack = callback;
				},
				menubar: 'file edit insert view format tools help',
				plugins: [
					'advlist autolink lists link image charmap print preview hr anchor pagebreak',
					'searchreplace wordcount visualblocks visualchars code  help fullscreen',
					'insertdatetime media save table contextmenu directionality',
					'paste textcolor colorpicker textpattern',
					// 'autoresize'
				],
				toolbar: `
					newdocument selectall | undo redo | styleselect | fontselect fontsizeselect | bold italic underline strikethrough removeformat | permanentpen formatpainter | hr |
					alignleft aligncenter alignright alignjustify | 
					image link media | 
					bullist numlist outdent indent |
					ltr rtl |
					forecolor backcolor | 
					searchreplace wordcount | 
					preview fullscreen | code
				`,
				resize: true,
				toolbar_mode: 'wrap',
				relative_urls: false,
				remove_script_host: false,
				language: this.$i18n.locale
			}
		}
	},

	methods: {
		setImage(imageUrl) {
			this.fileManager = false;
			const splittedUrl = imageUrl.split('/');
			const imageName = splittedUrl[splittedUrl.length - 1];
			this.imagePickerCallBack(imageUrl, { title: imageName })
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../css/tinyMCE/skins/ui/gray/skin.min.css";
</style>