<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="albums"
			:ids="deleteItemId"
			@on-delete="fetchAlbums"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Albums.the-album')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="550"
			:persistent="submitLoading"
		>
			<v-form ref="form" @submit.prevent="submit">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6 mb-3">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Albums.album')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row class="flex-column mt-0">
							<!-- title -->
							<v-col class="py-0">
								<v-text-field
									v-model="title"
									:label="$t('inputs.title')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- description -->
							<v-col class="py-0">
								<v-textarea
									v-model="description"
									:label="$t('inputs.description')"
									outlined
									dense
									rows="2"
									auto-grow
									no-resize
									class="rounded-small"
									:rules="rules.required"
								></v-textarea>
							</v-col>

							<!-- drop image -->
							<v-col md cols="12" class="flex-grow-1 pb-md-0">
								<v-row>
									<!-- main image -->
									<v-col sm="6" cols="12" class="pt-sm-0">
										<bee-drop-img
											v-model="image"
											dashed
											border-width="2"
											:border-color="isSubmitted && !image ? '#f00' : '#ccc'"
											radius="11"
											height="250px"
											blurable
											clearable
											:hide-image-name="false"
											:disabled="submitLoading"
										>
											<template v-slot:drag-text>
												<span class="text-body-1 font-weight-medium">
													{{$t('drag-text')}}
												</span>
												<span class="text-caption mb-0">
													{{$t('supported-text')}}
												</span>
											</template>
										</bee-drop-img>
									</v-col>

									<!-- other images -->
									<v-col sm="6" cols="12" class="pt-sm-0">
										<bee-drop-zone
											v-model="images"
											dashed
											drop-zone-classes="pa-3"
											radius="11"
											border-width="2"
											max-height="250"
											:drop-image-props="{
												width: 70,
												height: 70
											}"
											:clear-icon="editItemId ? 'mdi-delete' : 'mdi-close'"
											:clear-color="editItemId ? 'red' : null"
											:gap-size="4"
											:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
											:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
											:drop-menu-left="null"
											show-add-btn
											show-drop-menu
											no-drop-replace
											:disabled="submitLoading"
											@remove-image="removeImage"
										>
											<template v-slot:drag-text>
												<p class="mb-0">
													<span class="d-block text-body-1 font-weight-bold">
														{{$t('upload-more-image')}}
													</span>
													<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
												</p>
											</template>
										</bee-drop-zone>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="submitLoading"
							:disabled="submitLoading"
						>{{$t('save')}}</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="submitLoading"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		
		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Albums.add-new-album')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredAlbums"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- description -->
			<template v-slot:item.description="{ item }">
				{{item.content | isAvailable | ellipsis(130)}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{item}" >
				<div class="d-flex justify-center">
					<v-btn
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="editItemId = item.id; dialog = true;"
					><v-icon>mdi-pencil</v-icon></v-btn>

					<v-btn
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'Albums',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		dialog: false,
		editItemId: null,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		rules,

		// data
		title: null,
		description: null,
		image: null,
		images: [],
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					const item = this.getAlbumById(this.editItemId);
					this.title = item.title;
					this.description = item.content;
					this.image = item.image.url;
					this.images = item.images.map(c => c.url).filter(c => c !== item.image.url);
				}
			} else {
				this.editItemId = null;
				this.isSubmitted = null;
				this.$refs.form.reset();
			}
		}
	},

	computed: {
		...mapState({
			albums: state => state.albums.albums
		}),
		...mapGetters({
			getAlbumById: 'albums/getAlbumById'
		}),

		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.title'), value: 'title', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.description'), value: 'description', align: 'center', width: 500 },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filteredAlbums() {
			return this.filterByName(this.isFiltered, this.albums, this.search);
		}
	},
	methods: {
		submit() {
			this.isSubmitted = true;
			const images = this.images.filter(c => typeof c !== 'string');

			if (this.$refs.form.validate() && this.image) {
				const actionName = this.editItemId ? 'update' : 'create';
				
				this.submitLoading = true;

				this.$store.dispatch(`albums/${actionName}`, {
					id: this.editItemId,
					title: this.title,
					content: this.description,
					image: typeof this.image !== 'string' ? this.image : null,
				}).then((data) => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Albums.album') })
							: this.$t('$-is-created-successfully', { name: this.$t('Albums.album') });
					this.$eventBus.$emit('show-snackbar', false, message);
					
					// upload the other images
					if (images.length) {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'))
						this.$store.dispatch('albums/uploadImages', {
							id: data.id,
							images,
						}).then(() => {
							this.fetchAlbums();
							this.dialog = false;
						}).finally(() => {
							this.submitLoading = false;
						})
					} else {
						this.fetchAlbums();
						this.dialog = false;
					}
				}).finally(() => {
					if (!images.length) {
						this.submitLoading = false;
					}
				})
			}
		},

		fetchAlbums() {
			this.loading = true;
			this.$store.dispatch('albums/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.editItemId) {
				const item = this.getAlbumById(this.editItemId);
				const imageId = item.images.find(c => c.url === image)?.id;
				if (imageId) {
					this.$store.dispatch('albums/deleteImage', {
						id: this.editItemId,
						imageId: imageId
					})
				}
			}
		}
	},

	created() {
		this.fetchAlbums();
	}
};
</script>

<style>
</style>