import Vue from 'vue'
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import BeeDropImg from '@/components/BeeDropImg.vue'
import BeeDropZone from '@/components/BeeDropZone.vue'
import TextEditor from '@/components/TextEditor.vue'

export const $eventBus = new Vue();
const midad = {
	install: (Vue, options) => {
		Vue.prototype.$eventBus = $eventBus;

		Vue.component('BeeDeleteDialog', BeeDeleteDialog);
		Vue.component('BeeDropImg', BeeDropImg);
		Vue.component('BeeDropZone', BeeDropZone);
		Vue.component('TextEditor', TextEditor);

		Vue.filter('isAvailable', (val) => {
			return val || '-';
		})
		Vue.filter('ellipsis', (val, length = 50) => {
			return val.length < length ? val : val.slice(0, length) + '...';
		})
		
		Vue.mixin({
			methods: {
				filterByName(activeFilter, items, search, nameProp = 'name') {
					if (activeFilter) {
						return items.filter(c => !c.deleted_at && c[nameProp].toLowerCase().includes(search.toLowerCase()));
					} else {
						return items.filter(c => !c.deleted_at);
					}
				}
			}
		})
	}
}

Vue.use(midad, null);

export default { }