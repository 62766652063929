export const preview = {
	inPathName: 'preview',
	name: 'preview',
}

export const login = {
	inPathName: 'login',
	name: 'login',
}

export const dashboard = {
	inPathName: '',
	name: 'dashboard',
	show: true
}

export const sets = {
	inPathName: 'sets',
	name: 'sets',
	show: false
}

export const categories = {
	inPathName: 'categories',
	name: 'categories',
	show: true
}

export const category = {
	inPathName: 'category',
	name: 'category',
	show: false
}

export const filters = {
	inPathName: 'filters',
	name: 'filters',
	show: false
}

export const keywords = {
	inPathName: 'keywords',
	name: 'keywords',
	show: false
}

export const products = {
	inPathName: 'products',
	name: 'products',
	show: true
}

export const product = {
	inPathName: 'product',
	name: 'product',
	show: true,
	isAddEditPage: true
}

export const news = {
	inPathName: 'news',
	name: 'news',
	show: true
}

export const newsIndex = {
	inPathName: 'news',
	name: 'news-index',
	show: true,
	isAddEditPage: true
}

export const orders = {
	inPathName: 'orders',
	name: 'orders',
	show: false
}

export const order = {
	inPathName: 'order',
	name: 'order',
	show: false,
	isAddEditPage: false
}

export const albums = {
	inPathName: 'albums',
	name: 'albums',
	show: true
}

export const sliders = {
	inPathName: 'sliders',
	name: 'sliders',
	show: true
}

export const users = {
	inPathName: 'users',
	name: 'users',
	show: false
}

export const subscriptions = {
	inPathName: 'subscriptions',
	name: 'subscriptions',
	show: false
}

export const aboutUs = {
	inPathName: 'about-us',
	name: 'about-us',
	show: false
}

export const SEO = {
	inPathName: 'seo',
	name: 'seo',
	show: false
}