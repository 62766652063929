import { filters, keywords, sets, categories } from '@/configs/translates.config';

export default {
	search: {
		en: 'search',
		ar: ''
	},
	from: {
		en: 'from',
		ar: ''
	},
	to: {
		en: 'to',
		ar: ''
	},
	
	add: {
		en: 'Add',
		ar: ''
	},
	edit: {
		en: 'Edit',
		ar: ''
	},
	delete: {
		en: 'delete',
		ar: ''
	},
	save: {
		en: 'Save',
		ar: ''
	},
	cancel: {
		en: 'Cancel',
		ar: ''
	},
	close: {
		en: 'Close',
		ar: ''
	},
	back: {
		en: 'Back',
		ar: ''
	},
	select: {
		en: 'Select',
		ar: ''
	},
	upload: {
		en: 'Upload',
		ar: ''
	},
	'are-you-sure-you-want-to': {
		en: 'Are you sure you want to',
		ar: ''
	},

	'upload-images': {
		en: 'Upload images',
		ar: ''
	},
	'SEO-fields': {
		en: 'SEO fields',
		ar: ''
	},
	'drag-text': {
		en: 'Drag and drop image here',
		ar: ''
	},
	'upload-more-image': {
		en: 'Upload more images',
		ar: ''
	},
	'supported-text': {
		en: 'supported files: jpg, jpeg, png.',
		ar: ''
	},

	'please-wait-uploading-the-images': {
		en: 'Please wait uploading the images',
		ar: ''
	},

	'$-is-updated-successfully': {
		en: '{name} is updated successfully',
		ar: ''
	},
	'$-is-created-successfully': {
		en: '{name} is created successfully',
		ar: ''
	},

	headers: {
		en: {
			no: 'No',
			image: 'Image',
			name: 'Name',
			sets: sets.en.plural.charAt(0).toUpperCase() + sets.en.plural.slice(1),
			visits: 'Visits',
			orders: 'Orders',
			revenue: 'Revenue',
			summary: 'Summary',
			actions: 'Actions',
			category: 'Category',
			manufacturer: 'Manufacturer',
			quantity: 'Quantity',
			price: 'Price',
			title: 'Title',
			descriptions: 'descriptions',
			user: 'User',
			'created-at': 'Created at',
			total: 'Total',
			status: 'Status',
			'payment-type': 'Payment type',
			'current-status': 'Current status',
			address: 'Address',
			email: 'Email',
			phone: 'Phone',
			username: 'Username',
			link: 'Link',
			description: 'description',
			active: 'Active',
			date: 'Date',
			featured: 'Featured',
		},
		ar: {}
	},

	inputs: {
		en: {
			name: 'name',
			set: sets.en.singular,
			'parent-category': `parent ${categories.en.singular}`,
			'product-name': 'product name',
			'product-category': 'product category',
			summary: 'summary',
			description: 'description',
			category: 'category',
			summery: 'summery',
			keywords: keywords.en.plural,
			price: 'price',
			filter: filters.en.plural,
			file: 'file',
			link: 'link',
			'is-featured': 'is featured',
			'date-of-publication': 'Date of publication',
			date: 'Date',
			title: 'Title',
			username: 'Username',
			password: 'Password',
			'confirm-password': 'confirm Password',
			'seo-description': 'SEO description',
			'seo-keywords': 'SEO keywords',
		},
		ar: {}
	},

	messages: {
		en: {
			'deleted-successfully': 'Delete operation completed successfully',
			'there-is-uploaded-file': 'There\'s an uploaded file',
			'image-uploaded': 'Image uploaded successfully',
			'image-deleted': 'Image deleted successfully',
			'session-is-end': 'Your session is END, please Login again',
		},
		ar: {
			'deleted-successfully': 'عملية  الحذف تمت بنجاح',
			'there-is-uploaded-file': 'هناك ملف مرفوع',
			'image-uploaded': 'تم رفع الصورة بنجاح',
			'image-deleted': 'تم حذف الصورة بنجاح',
			'session-is-end': 'جلستك انتهت، الرجاء تسجيل الدخول مرة أخرى',
		}
	},

	components: {
		en: {
			'file-manger': 'File manger',
			filesFilters: {
				small: 'S',
				medium: 'M',
				large: 'L',
				original: 'Original',
			},
			fileTypes: {
				images: 'Images',
			},
			upload: 'Upload',
			'size-to-use': 'Size image to use',
			size: 'Size'
		},
		ar: {
			'file-manger': 'مدير الملفات',
			filesFilters: {
				small: 'صغير',
				medium: 'متوسطة',
				large: 'كبير',
				origanl: 'الأصلي',
			},
			fileTypes: {
				image: 'صور',
			},
			upload: 'رفع',
			'size-to-use': 'استخدم للصورة القياس',
			size: 'الحجم'
		},
	}
}