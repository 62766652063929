import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		keywords: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/keywords').then(({ data }) => {
				commit(types.STORE_KEYWORDS, data);
				return data;
			})
		},
		create({ commit }, { name }) {
			return axios.post('/keywords', { name })
		},
		update({ commit }, { id, name }) {
			return axios.post(`/keywords/update/${id}`, { name, _method: 'PUT' })
		},
		delete({ commit }, { id }) {
			return axios.delete(`/keywords/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/keywords/${id}`)
		},
	},

	mutations: {
		[types.STORE_KEYWORDS](state, keywords) {
			state.keywords = keywords;
		}
	},

	getters: {
		getKeywordById: state => id => state.keywords.find(c => c.id === id),
	}
}