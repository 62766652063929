import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		albums: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/albums').then(({ data }) => {
				commit(types.STORE_ALBUMS, data);
				return data;
			})
		},
		create({ commit }, { title, content, image }) {
			const formData = new FormData();
			const requestData = { title, content, image };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})
			return axios.post('/albums', formData).then(({ data }) => data)
		},

		update({ commit }, { id, title, content, image }) {
			const formData = new FormData();
			const requestData = { title, content, image };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})
			
			formData.append('_method', 'PUT');

			return axios.post(`/albums/${id}`, formData).then(({ data }) => data)
		},

		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/albums/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/albums/${id}/images/${imageId}`)
		},

		delete({ commit }, { id }) {
			return axios.delete(`/albums/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/albums/${id}`)
		},
	},

	mutations: {
		[types.STORE_ALBUMS](state, albums) {
			state.albums = albums;
		}
	},

	getters: {
		getAlbumById: state => id => state.albums.find(c => c.id === id),
	}
}