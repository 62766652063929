<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="categories"
			:ids="deleteItemId"
			@on-delete="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="400"
			:persistent="submitLoading"
		>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit">
					<v-card-title class="justify-center text-uppercase text-h6 mb-3">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Categories.category')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row class="flex-column mt-0">
							<!-- name -->
							<v-col class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- set -->
							<v-col v-if="!useCategoryTree" class="py-0">
								<v-autocomplete
									v-model="setIds"
									:items="sets"
									item-text="name"
									item-value="id"
									:label="$t('inputs.set')"
									outlined
									dense
									multiple
									class="rounded-small"
									:rules="rules.requiredAsArray"
								></v-autocomplete>
							</v-col>
							
							<template v-if="useCategoryTree">
								<!-- summery -->
								<v-col class="py-0">
									<v-text-field
										v-model="summery"
										:label="$t('inputs.summery')"
										outlined
										dense
										multiple
										class="rounded-small"
									></v-text-field>
								</v-col>

								<!-- parent categories -->
								<v-col class="py-0">
									<v-autocomplete
										v-model="parentCategoryId"
										:items="categories"
										item-text="name"
										item-value="id"
										:label="$t('inputs.parent-category')"
										outlined
										dense
										class="rounded-small"
										:rules="rules.requiredAsNumber"
									></v-autocomplete>
								</v-col>

								<!-- image -->
								<v-col class="pt-0">
									<bee-drop-img
										v-model="image"
										dashed
										border-width="2"
										:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
										radius="11"
										height="180px"
										blurable
										clearable
										:disabled="submitLoading"
									>
										<template v-slot:drag-text>
											<span class="text-body-1 font-weight-medium">
												{{$t('drag-text')}}
											</span>
											<span class="text-caption mb-0">
												{{$t('supported-text')}}
											</span>
										</template>
									</bee-drop-img>
								</v-col>
							</template>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="submitLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="6" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Categories.add-new-category')}}
						</v-btn>
					</v-col>
					<v-spacer/>

					<!-- sort & search -->
					<v-col lg="4" md="6" cols="12" class="text-md-start text-center">
						<v-row class="align-center">
							<v-col sm cols="12">
								<v-text-field
									v-model="search"
									:label="$t('search')"
									hide-details
									outlined
									dense
									prepend-inner-icon="mdi-magnify"
									class="rounded-medium"
								></v-text-field>
							</v-col>
							<v-col sm="auto" cols="12">
								<v-btn
									:color="isFiltered ? null : 'primary'"
									class="rounded-small"
									@click="isFiltered =! isFiltered"
								>
									{{isFiltered ? $t('cancel') : $t('search')}}
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- tree -->
		<v-card
			v-if="useCategoryTree"
			:loading="loading"
			class="rounded-medium"
		>
			<v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="pt-2 pb-1">
				<v-row class="flex-md-row flex-column align-center ma-0 ms-sm-8 text-body-2 font-weight-bold">
					<!-- name -->
					<v-col lg="3" class="py-2">{{$t('headers.name')}}</v-col>

					<!-- image -->
					<v-col lg="2" class="py-2">{{$t('headers.image')}}</v-col>

					<!-- summary -->
					<v-col lg="5" class="py-2">{{$t('headers.summary')}}</v-col>

					<!-- actions -->
					<v-col lg="2" class="text-end py-2 pe-7">{{$t('headers.actions')}}</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-treeview
					dense
					:items="items"
					:load-children="null"
					transition
					activatable
					open-all
					expand-icon="mdi-chevron-down"
					active-class
				>
					<template v-slot:label="{ item }">
						<v-row class="flex-md-row flex-column align-center tree-row ma-0">
							<!-- name -->
							<v-col lg="3" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.name')}}:
								</span>

								<span class="text-body-1 font-weight-medium primary--text">{{item.name}}</span>
							</v-col>

							<!-- image -->
							<v-col lg="2" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.image')}}:
								</span>

								<span class="text-body-1 font-weight-medium">
									<v-img
										v-if="true"
										:src="item.image || require('@/assets/placeholder-image.png')"
										:height="45"
										:width="45"
										lazy-src="@/assets/placeholder-image.png"
										class="rounded-medium"
									/>
									<template v-else-if="0">-</template>
								</span>
							</v-col>

							<!-- summary -->
							<v-col lg="5" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.summary')}}:
								</span>

								<p
									class="text-subtitle-2 main--text font-weight-medium mb-0 tree-row__summary"
								>
									{{item.summary | isAvailable | ellipsis(140)}}
								</p>
							</v-col>

							<!-- actions -->
							<v-col lg="2" class="text-md-end text-center py-2">
								<v-btn
									color="blue darken-4"
									dark
									small
									class="me-1 px-1 flex-shrink-1"
									min-width="20px"
									@click="editItemId = id; dialog = true;"
								><v-icon>mdi-pencil</v-icon></v-btn>
								<v-btn
									color="red darken-1"
									dark
									small
									class="px-1"
									min-width="20px"
									@click="deleteItemId = item.id; deleteDialog = true;"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-divider/>
					</template>
				</v-treeview>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			v-else
			:headers="headers"
			:items="loading ? [] : filteredCategories"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- sets -->
			<template v-slot:item.setsIds="{ item }">
				{{getSets(item) | isAvailable}}
			</template>

			<!-- action -->
			<template v-slot:item.actions="{ item }">
				<v-btn
					color="blue darken-4"
					dark
					small
					class="me-1 px-1 flex-shrink-1"
					min-width="20px"
					@click="dialog = true; editItemId = item.id"
				><v-icon>mdi-pencil</v-icon></v-btn>
				<v-btn
					color="red darken-1"
					dark
					small
					class="px-1"
					min-width="20px"
					@click="deleteDialog = true; deleteItemId = item.id"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { useCategoryTree } from '@/configs/global.config'
import { sets } from '@/configs/routes.config';
export default {
	name: 'Categories',

	data: () => ({
		dialog: false,
		editItemId: null,
		deleteDialog: false,
		deleteItemId: null,
		
		useCategoryTree,

		isSubmitted: false,

		// configs
		showSets: sets.show,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		submitLoading: false,
		rules,

		items: [
			{
				id: '1',
				name: 'Applications',
				numberOfProducts: 1,
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				image: require('@/assets/logo.png'),
				children: [
					{ id: '2', name: 'Calendar : app', numberOfProducts: 2 },
					{ id: '3', name: 'Chrome : app', numberOfProducts: 3 },
					{ id: '4', name: 'Webstorm : app', numberOfProducts: 4 },
				],
			},
			{
				id: '5',
				name: 'Documents',
				numberOfProducts: 5,
				image: require('@/assets/logo.png'),
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				children: [
					{
						id: '6',
						name: 'vuetify',
						numberOfProducts: 6,
						children: [
							{
								id: '7',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '8', name: 'index : ts', numberOfProducts: 8 },
									{ id: '9', name: 'bootstrap : ts', numberOfProducts: 9 },
								],
							},
						],
					},
					{
						id: '10',
						name: 'material2',
						numberOfProducts: 21,
						image: require('@/assets/logo.png'),
						summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
						children: [
							{
								id: '11',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '12', name: 'v-btn : ts', numberOfProducts: 12 },
									{ id: '13', name: 'v-card : ts', numberOfProducts: 13 },
									{ id: '14', name: 'v-window : ts', numberOfProducts: 14 },
								],
							},
						],
					},
				],
			}
		],

		// data
		name: null,
		setIds: [],
		summery: null,
		parentCategoryId: null,
		image: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					const item = this.getCategoryById(this.editItemId);
					this.name = item.name;
					this.setIds = item.setsIds;
				}
			} else {
				this.editItemId = null;
				this.isSubmitted = false;
				this.$refs.form.reset();
			}
		}
	},

	computed: {
		...mapState({
			categories: state => state.categories.categories,
			hierarchyCategories: state => state.categories.hierarchyCategories,
			sets: state => state.sets.sets.filter(c => !c.deleted_at),
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
			getSetById: 'sets/getSetById',
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];
			if (this.showSets) {
				headers.splice(1, 0, { text: this.$t('headers.sets'), value: 'setsIds', align: 'center' });
			}
			return headers
		},

		filteredCategories() {
			return this.filterByName(this.isFiltered, this.categories, this.search);
		}
	},

	methods: {
		getSets(item) {
			if (item.setsIds && item.setsIds.length) {
				const names = [];
				item.setsIds.forEach(id => {
					const set = this.getSetById(id).name;
					names.push(set)
				});
				return names.join(', ');
			}
		},

		submit() {
			this.isSubmitted = true;
			const state = this.useCategoryTree ? this.image : true;
			if (this.$refs.form.validate() && state) {
				const actionName = this.editItemId ? 'update' : 'create';
				this.submitLoading = true;
				this.$store.dispatch(`categories/${actionName}`, {
					id: this.editItemId,
					name: this.name,
					setIds: this.setIds,
					categoryId: this.parentCategoryId,
					summary: this.summery,
					image: this.image,
				}).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Categories.category') })
							: this.$t('$-is-created-successfully', { name: this.$t('Categories.category') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.dialog = false;
					this.fetchCategories();
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		fetchCategories() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('categories/fetchAll'),
				useCategoryTree ? this.$store.dispatch('categories/fetchAllAsHierarchy') : null
			]).finally(() => {
				this.loading = false;
			})
		}
	},

	created() {
		this.fetchCategories();
		if (this.sets.length === 0) {
			this.$store.dispatch('sets/fetchAll')
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-row {
	&:hover {
		background-color: #eee;
	}
	&__summary {
		white-space: pre-line;
	}
}
</style>