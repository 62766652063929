<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="news"
			:ids="deleteItemId"
			@on-delete="fetchNews"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('News.the-news')}}
		</bee-delete-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
							:to="{ name: 'news-index' }"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('News.add-new-news')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredNews"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- summary -->
			<template v-slot:item.summary="{ item }">
				{{item.summary | isAvailable | ellipsis}}
			</template>

			<!-- date -->
			<template v-slot:item.date="{ item }">
				{{item.date ? moment(item.date).format('DD-MM-YYYY') : '-'}}
			</template>

			<!-- is featured -->
			<template v-slot:item.isFeatured="{ item }">
				<v-icon v-if="item.isFeatured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- acitons -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center">
                    <v-btn
						color="deep-purple darken-2 white--text"
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'preview', query: { url: `${previewUrl}/${item.slug}` } }"
					><v-icon>mdi-eye</v-icon></v-btn>
					<v-btn
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'news-index', params: { slug: item.slug, id: item.id } }"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment'
import { news as newsPreview } from '@/configs/preview.config'
export default {
	name: 'Products',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		previewUrl: newsPreview
	}),

	computed: {
		...mapState({
			news: state => state.news.news
		}),

		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.title'), value: 'title', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.summary'), value: 'summary', align: 'center' },
				{ text: this.$t('headers.date'), value: 'date', align: 'center' },
				{ text: this.$t('headers.featured'), value: 'isFeatured', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filteredNews() {
			return this.filterByName(this.isFiltered, this.news, this.search, 'Title');
		}
	},

	methods: {
		fetchNews() {
			this.loading = true;
			this.$store.dispatch('news/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},

		// helpers
		moment
	},

	created() {
		this.fetchNews();
	}
};
</script>

<style>
</style>